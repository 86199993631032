import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MixpanelService } from './mixpanel.service';

export function Track(eventName: string, properties?: { [key: string]: any }) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const result = originalMethod.apply(this, args);

      MixpanelService.trackEvent(eventName, properties);

      return result;
    };

    return descriptor;
  };
}

export function TrackPageViews(eventName: string, properties?: { [key: string]: any }) {
  return function (constructor: any) {
    const originalOnInit = constructor.prototype.ngOnInit;

    constructor.prototype.ngOnInit = function () {
      if (originalOnInit) {
        originalOnInit.apply(this);
      }
      MixpanelService.trackPageView(eventName, properties);
    };

    return constructor;
  };
}
